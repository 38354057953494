import React, {
  useState,
  useEffect,
  createRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { InputField } from "elements";
import ErrorTooltip from "../../../../components/ErrorTooltip";

interface IProps {
  name: string;
  defaultValue: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: string, name: string) => void;
  type?: string;
  error?: string;
  maxWordLength?: number;
  textCenter?: boolean;
  setIsEditItemText?: (value: boolean) => void;
  isEditItemText?: boolean;
  notEditOnText?: boolean;
  setInputValue?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isBlur?: boolean;
  isErrorName?: boolean;
  setIsErrorName?: (value: boolean) => void;
}

interface IHandle {
  focusInput: () => void;
}

const InputWrapper = forwardRef<IHandle, IProps>(
  (
    {
      name,
      defaultValue,
      placeholder,
      disabled,
      onChange,
      type,
      maxWordLength,
      error,
      textCenter = true,
      setIsEditItemText,
      isEditItemText,
      notEditOnText,
      setInputValue,
      onKeyDown,
      isBlur = true,
      isErrorName,
      setIsErrorName,
    },
    ref
  ) => {
    const [value, setValue] = useState("");
    const [isEdit, setIsEdit] = useState(false);

    const $inputRef = createRef<HTMLInputElement>();

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        setIsEdit(true);
        setIsEditItemText && setIsEditItemText(true);
      },
    }));
    useEffect(() => {
      if (
        (isEdit && $inputRef.current) ||
        (isEditItemText && $inputRef.current)
      ) {
        $inputRef.current.focus();
      }
    }, [isEdit, isEditItemText, $inputRef]);

    useEffect(() => {
      setValue(defaultValue || "");
    }, [isEditItemText]);

    const handleEdit = () => {
      if (disabled) return;

      setIsEdit(true);
      setIsEditItemText && setIsEditItemText(true);
      setValue(defaultValue || "");
    };

    const handleBlur = () => {
      if (isBlur) {
        setIsEdit(false);
        setIsEditItemText && setIsEditItemText(false);
        onChange(value, name);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown && onKeyDown(e);
      if (type === "number") {
        ["e", "E", "+"].includes(e.key) && e.preventDefault();
      }
      if (e.key === "Enter") {
        setIsEdit(false);
        setIsEditItemText && setIsEditItemText(false);
        onChange(value, name);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let input = e.target.value;

      if (maxWordLength && input.length > maxWordLength) {
        input = input.slice(0, maxWordLength);
      }

      setValue(input);

      setInputValue && setInputValue(input);
    };

    if (isEdit || isEditItemText) {
      return (
        <>
          <InputField
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            ref={$inputRef}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </>
      );
    }

    return (
      <span
        className={`cursor-pointer w-100 d-flex align-items-center gap-1 ${
          textCenter && "text-center  justify-content-center"
        }`}
        onClick={handleEdit}
      >
        {defaultValue || placeholder}

        {error && <ErrorTooltip title={error || ""} />}
      </span>
    );
  }
);

export default InputWrapper;
