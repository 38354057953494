import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { toast } from "react-toastify";

import { ReactComponent as Close } from "assets/svg/closeBox.svg";

import {
  upateActionItem,
  // @ts-ignore
} from "modules/actions/PlanActions";

// @ts-ignore
import { onlySpaces } from "utils/utility";

import {
  upateNotes,
  createNotes,
  deleteNotes,
  // @ts-ignore
} from "modules/actions/PlanActions";
import { INote } from "../ActionItems/@types";
import styled from "styled-components";

interface IAddNoteProps {
  data: INote | null;
  isVisible: boolean;
  isEdit?: boolean;
  actionItemId: number;
  planId: number;
  onClose: () => void;
  isDeleted?: boolean;
  isAdd?: boolean;
  isName?: string;
  allData?: any;
}

enum LOADING {
  DELETE = "delete",
  SAVE = "save",
}

const AddNoteModal: FC<IAddNoteProps> = ({
  data,
  isVisible,
  isEdit,
  planId,
  actionItemId,
  onClose,
  isAdd,
  isName,
  allData,
}) => {
  const dispatch = useDispatch();

  const [height, setHeight] = useState<number>(84);
  const [loading, setLoading] = useState<LOADING | false>(false);
  const [value, setValue] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [itemName, setItemName] = useState(allData?.name || "");

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  // @ts-ignore
  const { loadingNote } = useSelector((state) => state.plan.plan);

  useEffect(() => {
    if (!isVisible) return;

    if (!isAdd && isName) {
      setItemName(isName);
    }

    if (data) {
      setValue(data.context);
      setInitialValue(data.context);
    }
  }, [data?.context, isVisible, isName]);

  const handleChange = (_: string, value: string) => {
    setValue(value);
  };

  const closeAll = () => {
    setValue("");
    onClose();
  };

  const handleUpdateNote = () => {
    // if (onlySpaces(value)) {
    //   toast("Please enter notes", { type: "error" });
    //   return;
    // }

    if (!isAdd && onlySpaces(itemName)) {
      toast("Please enter action item name", { type: "error" });
      return;
    }

    if (value !== initialValue && data?.id) {
      setLoading(LOADING.SAVE);
      dispatch(
        upateNotes(data?.id, { context: value }, planId, handleCallback)
      );
    } else if (value !== initialValue) {
      const payload = {
        context: value,
        action_item: data?.action_item || actionItemId,
      };
      dispatch(createNotes(payload, planId, handleCallback));
    }

    if (!isAdd && isName !== itemName) {
      dispatch(upateActionItem(actionItemId, { name: itemName }, planId, true));
      handleCallback();
    }
  };

  const handleAddNote = () => {
    // if (onlySpaces(value)) {
    //   toast("Please enter notes", { type: "error" });
    //   return;
    // }

    if (!isAdd && onlySpaces(itemName)) {
      toast("Please enter action item name", { type: "error" });
      return;
    }

    const payload = {
      context: value,
      action_item: data?.action_item || actionItemId,
    };

    if (value !== initialValue) {
      setLoading(LOADING.SAVE);
      dispatch(createNotes(payload, planId, handleCallback));
      return;
    }

    if (!isAdd && isName !== itemName) {
      dispatch(upateActionItem(actionItemId, { name: itemName }, planId, true));
      handleCallback();
    }
  };

  const handleDeleteNote = () => {
    setLoading(LOADING.DELETE);
    dispatch(deleteNotes(data?.id, planId, handleCallback));
  };

  const handleCallback = () => {
    setLoading(false);
    setValue("");
    onClose();
  };

  const handleActionItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;

    if (input?.startsWith(" ")) {
      input = input.trim();
    }

    if (input.length > 255) {
      input = input.slice(0, 255);
    }

    setItemName(input);
  };

  const handleMouseDown = (e: MouseEvent<SVGSVGElement>): void => {
    const startY = e.clientY;
    const startHeight = textAreaRef.current?.offsetHeight || 84;

    const handleMouseMove = (e: MouseEvent): void => {
      const newHeight = startHeight + (e.clientY - startY);
      if (newHeight >= 50 && newHeight <= 150) {
        setHeight(newHeight);
      }
    };

    const handleMouseUp = (): void => {
      document.removeEventListener("mousemove", handleMouseMove as any);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove as any);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <Modal
      footer={null}
      closable={false}
      onCancel={closeAll}
      title={false}
      open={isVisible}
      width={750}
      centered
      className="disablePadding"
    >
      <ModelHead>
        <ModelHeading>Update</ModelHeading>

        <Close
          style={{
            cursor: "pointer",
          }}
          onClick={closeAll}
        />
      </ModelHead>

      <ModelContent>
        {!isAdd && (
          <>
            <NoteHeading>Action Item Name</NoteHeading>
            <AppInput
              type="text"
              className="input"
              placeholder="Action Item Name"
              value={itemName}
              onChange={handleActionItemChange}
            />
          </>
        )}
      </ModelContent>

      <ModelActions>
        <Button type="dashed" onClick={closeAll}>
          Cancel
        </Button>

        <Button
          type="primary"
          disabled={
            isEdit
              ? !(value !== initialValue || isName !== itemName) || !!loading
              : !value || !!loading
          }
          loading={loadingNote || loading === LOADING.SAVE}
          onClick={handleAddNote}
        >
          Save
        </Button>
      </ModelActions>
    </Modal>
  );
};

export default AddNoteModal;

const ModelHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 24px;
`;

const ModelHeading = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #171717;
`;

const ModelContent = styled.div`
  padding: 16px 24px;
`;

const TexAreaContainer = styled.div`
  position: relative;
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;

  svg {
    position: absolute;
    bottom: 10px;
    right: 4px;
    cursor: ns-resize;
  }
`;

const TexArea = styled.textarea`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 12px 8px;
  height: 84px;
  max-height: 130px;
  min-height: 50px;
  width: 100%;
  resize: none;
  color: #171717;
  font-size: 14px;
  font-weight: 400;

  ::placeholder {
    color: #e6e7eb !important;
  }

  :focus {
    outline: 2px solid #d0e3ed;
    box-shadow: 0 0 0 2px #d0e3ed;
  }
`;

const NoteHeading = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #737373;
  margin-bottom: 6px;
`;

const AppInput = styled.input`
  border: 1px solid #e3e5e9;
  border-radius: 4px;
  padding: 10px 12px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 16px;
  color: #171717;
  font-weight: 400;

  ::placeholder {
    color: #e6e7eb !important;
  }

  :focus {
    outline: 2px solid #d0e3ed;
    box-shadow: 0 0 0 2px #d0e3ed;
  }
`;

const ModelActions = styled.div`
  padding-top: 16px;
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px 24px;
  background-color: #f5f5f5;

  button {
    background-color: #1271a6 !important;
    padding-left: 20px;
    padding-right: 20px;
    :disabled {
      background-color: #71aaca !important;
      color: #fff !important;
    }
  }

  button:nth-child(1) {
    border: 1px solid #1271a6;
    background-color: transparent !important;
    border-radius: 2px !important;
    color: #1271a6;
    font-size: 14px !important;
  }
`;
