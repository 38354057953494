import { Col, DatePicker, Modal, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
// @ts-ignore
import classes from "./ActionItemsModal.module.scss";
// @ts-ignore
import AppInput from "../../components/AppInput";

import { ReactComponent as CalanderIcon } from "assets/svg/calander.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrowDownGray.svg";
// @ts-ignore
import Button from "../Button";
import { Select } from "antd";
import moment from "moment";
// @ts-ignore
import { durationList } from "../../utils/constants";
import { ReactComponent as TodoIcon } from "./../../scenes/PlanDetails/ActionItems/Category//todo.svg";
import { ReactComponent as InProgessIcon } from "./../../scenes/PlanDetails/ActionItems/Category//inProgress.svg";
import { ReactComponent as OnHoldIcon } from "./../../scenes/PlanDetails/ActionItems/Category//onHold.svg";
import { ReactComponent as CompleteIcon } from "./../../scenes/PlanDetails/ActionItems/Category/complete.svg";
import { ReactComponent as CancelIcon } from "./../../scenes/PlanDetails/ActionItems/Category/canceled.svg";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import {
  getProjectUsers,
  getProjectList,
  // @ts-ignore
} from "../../modules/actions/UserActions";
import InviteUserModal from "../../scenes/PlanDetails/ActionItems/InviteUserModal";
// @ts-ignore
import { createNewActionItemsModal } from "../../modules/actions/PlanActions";
import { LoadingOutlined } from "@ant-design/icons";

interface ActionItemsModalProps {
  isActionItem?: boolean;
  actionItemsModal: boolean;
  setActionItemsModal: any;
  data: any;
  isName?: string;
  projectPlanId: any;
  PlanDetails: any;
  allData: any;
  isOnwer?: any;
}
interface InitialValues {
  ActionItemName: string;
  context: string;
  start_line: string | null;
  dead_line: string | null;
  weight: number | null;
  start: any | null;
  current: any | null;
  target: any | null;
  duration: number | null;
  plan_progress: number | null;
  Priority: number | null;
  category: string | null;
  Status: number | null;
  Assignee: string | null;
  project_plan_name: string | null;
  category_name: string | null;
  project_plan_id: string | null;
}
export const ActionItemsModal: React.FC<ActionItemsModalProps> = ({
  isActionItem,
  actionItemsModal,
  setActionItemsModal,
  data,
  projectPlanId,
  allData,
  isOnwer,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { projectUser, projectList } = useSelector(
    (state: any) => state.user.projectOwners
  );
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    ActionItemName: allData?.name,
    context: "",
    start_line: allData?.start_line,
    dead_line: allData?.dead_line,
    weight: allData?.weight,
    start: allData?.start,
    current: allData?.current,
    target: allData?.target,
    duration: allData?.duration,
    plan_progress: allData?.plan_progress,
    Priority: allData?.priority,
    category: allData?.category,
    Status: allData?.status,
    Assignee: allData?.responsible_person?.email,
    project_plan_name: allData?.project_plan_name,
    category_name: allData?.category_name,
    project_plan_id: allData?.project_plan_id,
    description: allData?.description,
  };

  const [values, setValues] = useState<InitialValues>(initialValues);
  const [hasChanged, setHasChanged] = useState(false);
  const [note, setNote] = useState<string | null>(null);
  const [assigneeID, setAssigneeId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [emailToInvite, setEmailToInvite] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [category, setCategory] = useState<any[]>([]);

  useEffect(() => {
    const isChanged = Object.keys(initialValues).some(
      (key) =>
        values[key as keyof InitialValues] !==
        initialValues[key as keyof InitialValues]
    );
    setHasChanged(isChanged);
    const isActionItemNameEmpty = values.ActionItemName?.trim().length === 0;
    if (isActionItemNameEmpty) {
      setHasChanged(!isActionItemNameEmpty);
    } else {
      setHasChanged(isChanged);
    }
  }, [values]);

  useEffect(() => {
    dispatch(getProjectUsers());
    dispatch(getProjectList());
  }, [dispatch]);

  useEffect(() => {
    setNote(allData?.description || "");
  }, [allData]);

  useEffect(() => {
    const project = projectList?.filter(
      (item: any) => item.id == allData?.project_plan_id
    );
    if (project) {
      setCategory(project[0]?.action_categories);
    }
  }, [projectList]);

  const selectedUser = projectUser.find((user: any) => user?.id === assigneeID);

  const momentDeadLine = values?.dead_line
    ? moment(values?.dead_line, "YYYY-MM-DD").format("YYYY-MM-DD")
    : null;

  const momentStartLine = values?.start_line
    ? moment(values?.start_line, "YYYY-MM-DD").format("YYYY-MM-DD")
    : null;

  const AssignOptions = projectUser?.map((user: any) => ({
    label: user.email,
    value: user.id,
    image: user?.profile_picture,
    initials: user.name
      ? user.name.match(/\b\w/g)?.join("")
      : user.email[0].toUpperCase(),
  }));

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const userExists = AssignOptions.some(
      (option: any) => option.label && option.label.includes(value)
    );
    setShowInvite(isEmailValid && !userExists);
  };

  const handleInviteClick = () => {
    setIsModalVisible(true);
    setEmailToInvite(searchTerm);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setEmailToInvite(null);
    setSearchTerm("");
    setShowInvite(false);
    dispatch(getProjectUsers());
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const handleClose = () => {
    setActionItemsModal && setActionItemsModal(false);
    setIsLoading(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const actionItem = {
      id: allData?.id,
      name: values?.ActionItemName,
      weight: values?.weight,
      start: values?.start ? Number(values?.start).toFixed(2) : null,
      current: values?.current ? Number(values?.current).toFixed(2) : null,
      target: values?.target ? Number(values?.target).toFixed(2) : null,
      start_line: momentStartLine,
      dead_line: momentDeadLine,
      duration: values?.duration,
      responsible_person: assigneeID || allData?.responsible_person?.id || "",
      status: values?.Status,
      plan_progress: values?.plan_progress,
      priority: values?.Priority,
      project_plan: Number(values?.project_plan_id),
      category: values?.category,
      description: note,
    };

    const notePayload = {
      action_item: allData?.id,
      // description: note,
    };

    const payload = { action_item: actionItem, note: notePayload };
    const plan_id = projectPlanId || values?.project_plan_id;

    await dispatch(createNewActionItemsModal(payload, plan_id, handleClose));
  };

  const updateActionStatus = (value: number) => {
    let updatedStatus: any = values.Status;
    if (value === 10) {
      updatedStatus = 4;
    } else if (value === 0) {
      updatedStatus = 1;
    } else {
      updatedStatus = 2;
    }

    setValues({
      ...values,
      plan_progress: value,
      Status: updatedStatus,
    });
  };

  const updateProgress = (value: number) => {
    let updatedPlanProgress = values.plan_progress;
    if (value === 4) {
      updatedPlanProgress = 10;
    } else if (value === 1) {
      updatedPlanProgress = 0;
    } else if (
      value === 2 &&
      (updatedPlanProgress === 0 ||
        updatedPlanProgress === 10 ||
        updatedPlanProgress === null)
    ) {
      updatedPlanProgress = 5;
    }
    setValues({
      ...values,
      Status: value,
      plan_progress: updatedPlanProgress,
    });
  };

  return (
    <>
      <Modal
        centered
        open={actionItemsModal}
        closable={false}
        bodyStyle={{ padding: "10px", backgroundColor: "#FAFAFA" }}
        width={1053}
        footer={
          <div
            className="d-flex  gap-2 justify-content-end"
            style={{
              backgroundColor: "#FAFAFA",
              padding: "10px 20px 10px 10px",
              margin: "-10px",
            }}
          >
            <Button
              style={{
                backgroundColor: "transparent",
                borderRadius: "2px",
                color: "#1271A6",
                border: "1px solid #1271A6",
                width: "88px",
              }}
              onClick={() => setActionItemsModal(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              disabled={!hasChanged || isLoading}
              style={{
                backgroundColor: "#1271A6",
                color: "white",
                width: "88px",
                border: "1px solid #1271A6",
                borderRadius: "2px",
                opacity: !hasChanged ? 0.5 : 1,
              }}
            >
              {isLoading ? <LoadingOutlined /> : "Save"}
            </Button>
          </div>
        }
      >
        <div className={classes.wrapperModal}>
          <div className={classes.header}>
            <span>Action Item Details</span>
            <button
              type="button"
              onClick={() => setActionItemsModal(false)}
              className={classes.close}
              title="Close"
            >
              X
            </button>
          </div>
          <Row>
            <Col md={18} className={classes.LeftSide}>
              <label className={classes.label}>Action Item Name</label>
              <AppInput
                type="text"
                className="input"
                placeholder="Action Item Name"
                value={values?.ActionItemName}
                onChange={(name: string, value: string) => {
                  setValues({ ...values, ActionItemName: value });
                }}
                error={values?.ActionItemName?.length == 0 ? true : false}

                // value={allData?.name}
                // onChange={handleActionItemChange}
              />

              {values?.ActionItemName?.trim()?.length == 0 && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  This field may not be blank
                </div>
              )}

              <label className={classes.label} htmlFor="">
                Description
              </label>
              <textarea
                placeholder="Add a description"
                value={note || ""}
                onChange={(e) => {
                  let value = e.target.value;

                  if (value?.startsWith(" ")) {
                    value = value.trim();
                  }

                  setNote(value);
                  setHasChanged(true);
                }}
              ></textarea>

              {!isActionItem && (
                <>
                  <label className={classes.label}>Project</label>
                  <Select
                    dropdownStyle={{
                      width: 300, // Set your custom width here
                    }}
                    dropdownMatchSelectWidth={false}
                    size="large"
                    placeholder="Plan Projects"
                    style={{
                      width: "100%",
                      border: "1px solid #E5E5E5",
                      maxWidth: "100%",
                    }}
                    suffixIcon={<ArrowDown />}
                    value={values?.project_plan_name}
                    onChange={(value: any) => {
                      const project = projectList?.find(
                        (item: any) => item.id == value
                      );
                      if (project) {
                        setCategory(project?.action_categories);
                      }
                      setValues({
                        ...values,
                        project_plan_name: project?.name,
                        project_plan_id: value,
                        category_name: project?.action_categories[0]?.name,
                        category: project?.action_categories[0]?.id,
                      });
                    }}
                    showSearch
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {projectList.map((p?: any) => (
                      <Option key={String(p.id)} value={String(p.id)}>
                        {p.name}
                      </Option>
                    ))}
                  </Select>
                </>
              )}

              <label className={classes.label}>Category</label>
              <Select
                dropdownStyle={{
                  width: 300, // Set your custom width here
                }}
                dropdownMatchSelectWidth={false}
                size="large"
                placeholder="Category"
                disabled={allData?.project_plan_name === 0}
                style={{
                  width: "100%",
                  border: "1px solid #E5E5E5",
                }}
                suffixIcon={<ArrowDown />}
                value={values?.category_name}
                onChange={(value) => {
                  const filterCategory = category?.find(
                    (item) => item.id === value
                  );
                  if (filterCategory) {
                    setValues({
                      ...values,
                      category_name: filterCategory.name,
                      category: value,
                    });
                  }
                }}
                showSearch
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {category?.map((c: any, index: any) => (
                  <Option key={index} value={c.id}>
                    {c.name}
                  </Option>
                ))}
              </Select>

              <Row>
                <Col md={8}>
                  <div className={classes.box}>
                    <label className={classes.label} htmlFor="">
                      Start Date
                    </label>
                    <DatePicker
                      size="large"
                      allowClear
                      placeholder="MM/DD/YYYY"
                      className="antd-suffixIcon"
                      inputReadOnly={true}
                      disabled={!isOnwer}
                      suffixIcon={<CalanderIcon />}
                      style={{ width: "100%" }}
                      value={
                        values?.start_line ? moment(values?.start_line) : null
                      }
                      disabledDate={(current) => {
                        const startLine = allData?.category_details?.start_line
                          ? moment(
                              allData?.category_details.start_line
                            ).startOf("day")
                          : null;
                        const deadLine = allData?.category_details?.dead_line
                          ? moment(allData?.category_details?.dead_line).endOf(
                              "day"
                            )
                          : null;

                        // If startLine is null, allow all dates. Otherwise, restrict as per conditions.
                        return (
                          current &&
                          ((startLine && current.isBefore(startLine)) ||
                            (deadLine && current.isAfter(deadLine)) ||
                            current.isAfter(values?.dead_line))
                        );
                      }}
                      onChange={(date, value) => {
                        setValues({
                          ...values,
                          start_line: date
                            ? moment(date).format("YYYY-MM-DD")
                            : null,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <div className={classes.box}>
                    <label className={classes.label} htmlFor="">
                      Due Date
                    </label>
                    <DatePicker
                      size="large"
                      allowClear
                      placeholder="MM/DD/YYYY"
                      className="antd-suffixIcon"
                      inputReadOnly={true}
                      suffixIcon={<CalanderIcon />}
                      disabled={!isOnwer}
                      style={{ width: "100%" }}
                      value={
                        values?.dead_line ? moment(values?.dead_line) : null
                      }
                      disabledDate={(current) => {
                        const startLine = allData?.category_details?.start_line
                          ? moment(allData.category_details.start_line).startOf(
                              "day"
                            )
                          : null;
                        const deadLine = allData?.category_details?.dead_line
                          ? moment(allData?.category_details.dead_line).endOf(
                              "day"
                            )
                          : null;
                        return (
                          current &&
                          ((startLine && current.isBefore(startLine)) ||
                            (deadLine && current.isAfter(deadLine)) ||
                            current.isBefore(values?.start_line))
                        );
                      }}
                      onChange={(date, value) => {
                        setValues({
                          ...values,
                          dead_line: date
                            ? moment(date).format("YYYY-MM-DD")
                            : null,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <label className={classes.label} htmlFor="">
                    Weight
                  </label>
                  <Select
                    dropdownMatchSelectWidth={false}
                    size="large"
                    placeholder="Weight"
                    style={{
                      width: "100%",
                      border: "1px solid #E5E5E5",
                    }}
                    suffixIcon={<ArrowDown />}
                    value={values?.weight}
                    onChange={(value) => {
                      setValues({ ...values, weight: value });
                    }}
                  >
                    {[
                      { value: 1, label: "1" },
                      { value: 2, label: "2" },
                      { value: 3, label: "3" },
                      { value: 4, label: "4" },
                      { value: 5, label: "5" },
                      { value: 6, label: "6" },
                      { value: 7, label: "7" },
                      { value: 8, label: "8" },
                      { value: 9, label: "9" },
                      { value: 10, label: "10" },
                    ].map((count: any, index: any) => (
                      <Option key={index} value={count.value}>
                        {count.key}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <div className={classes.box}>
                    <label className={classes.label} htmlFor="">
                      Start Value
                    </label>
                    <AppInput
                      type="number"
                      className="input"
                      placeholder="Start Value"
                      value={values.start}
                      onChange={(name: string, value: string) => {
                        setValues({ ...values, start: value });
                      }}
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <div className={classes.box}>
                    <label className={classes.label} htmlFor="">
                      Current Value
                    </label>
                    <AppInput
                      type="number"
                      className="input"
                      placeholder="Current Value"
                      value={values.current}
                      onChange={(name: string, value: string) => {
                        setValues({ ...values, current: value });
                      }}
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <label className={classes.label} htmlFor="">
                    Target Value
                  </label>
                  <AppInput
                    type="number"
                    className="input"
                    placeholder="Target Value"
                    value={values.target}
                    onChange={(name: string, value: string) => {
                      setValues({ ...values, target: value });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                {/*
                <Col md={8}>
                  <label className={classes.label} htmlFor="">
                    Criteria Tags
                  </label>
                  <div className={classes.box}>
                    <Select
                      mode="multiple"
                      placeholder="Criteria Tags"
                      tagRender={tagRender}
                      defaultValue={[options[0].value, options[1].value]}
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        flexWrap: "unset",
                        padding: "5px",
                        border: "1px solid #E5E5E5",
                      }}
                      options={options}
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </div>
                </Col> */}

                <Col md={8}>
                  <div className={classes.box}>
                    <label className={classes.label} htmlFor="">
                      Priority
                    </label>

                    <Select
                      dropdownMatchSelectWidth={false}
                      size="large"
                      placeholder="Priority"
                      style={{
                        width: "100%",
                        border: "1px solid #E5E5E5",
                      }}
                      suffixIcon={<ArrowDown />}
                      value={values?.Priority}
                      onChange={(value) => {
                        setValues({ ...values, Priority: value });
                      }}
                    >
                      {[
                        { value: 3, label: "High" },
                        { value: 2, label: "Medium" },
                        { value: 1, label: "Low" },
                      ].map((count, index) => (
                        <Option key={index} value={count.value}>
                          {count.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col md={8}>
                  <div className={classes.box}>
                    <label
                      className={classes.label}
                      htmlFor=""
                      style={{ backgroundColor: "#fffff" }}
                    >
                      Plan Progress
                    </label>

                    <Select
                      dropdownMatchSelectWidth={false}
                      size="large"
                      placeholder="Plan Progress"
                      style={{
                        width: "100%",
                        border: "1px solid #E5E5E5",
                      }}
                      suffixIcon={<ArrowDown />}
                      value={values.plan_progress}
                      onChange={(value: number) => {
                        updateActionStatus(value);
                      }}
                    >
                      {[
                        { value: 0, label: "0" },
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 3, label: "3" },
                        { value: 4, label: "4" },
                        { value: 5, label: "5" },
                        { value: 6, label: "6" },
                        { value: 7, label: "7" },
                        { value: 8, label: "8" },
                        { value: 9, label: "9" },
                        { value: 10, label: "10" },
                      ].map((count, index) => (
                        <Option key={index} value={count.value}>
                          {count.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col md={8}>
                  <label className={classes.label} htmlFor="">
                    Status
                  </label>

                  <Select
                    dropdownMatchSelectWidth={false}
                    size="large"
                    placeholder="Status"
                    style={{
                      width: "100%",
                      border: "1px solid #E5E5E5",
                    }}
                    suffixIcon={<ArrowDown />}
                    value={values.Status}
                    onChange={(value: any) => {
                      updateProgress(value);
                    }}
                  >
                    <Select.Option value="" disabled>
                      Select
                    </Select.Option>
                    <Select.Option value={1}>
                      <TodoIcon style={{ marginRight: 8 }} />
                      TODO
                    </Select.Option>
                    <Select.Option value={2}>
                      <InProgessIcon style={{ marginRight: 8 }} />
                      IN PROGRESS
                    </Select.Option>
                    <Select.Option value={3}>
                      <OnHoldIcon style={{ marginRight: 8 }} />
                      ON HOLD
                    </Select.Option>
                    <Select.Option value={4}>
                      <CompleteIcon style={{ marginRight: 8 }} />
                      COMPLETED
                    </Select.Option>
                    <Select.Option value={5}>
                      <CancelIcon style={{ marginRight: 8 }} />
                      CANCELED
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <div className={classes.box}>
                    <label className={classes.label} htmlFor="">
                      Duration
                    </label>
                    <Select
                      dropdownMatchSelectWidth={false}
                      size="large"
                      placeholder="-- --"
                      style={{
                        width: "100%",
                        border: "1px solid #E5E5E5",
                      }}
                      suffixIcon={<ArrowDown />}
                      value={values?.duration}
                      onChange={(value) => {
                        setValues({ ...values, duration: value });
                      }}
                    >
                      {durationList.map((count: any, index: any) => (
                        <Option key={index} value={count.value}>
                          {count.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col md={8}>
                  <div className={classes.box}>
                    <label className={classes.label} htmlFor="">
                      Assignee
                    </label>
                    <Select
                      size="large"
                      placeholder="--"
                      style={{ width: "100%", border: "1px solid #E5E5E5" }}
                      // className="antd-assignee"
                      value={
                        selectedUser
                          ? {
                              key: selectedUser,
                              label: (
                                <Tooltip
                                  title={selectedUser.email}
                                  placement="top"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {selectedUser?.profile_picture ? (
                                      <img
                                        src={selectedUser?.profile_picture}
                                        style={{
                                          minWidth: 24,
                                          height: 24,
                                          borderRadius: "50%",
                                          marginRight: 8,
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          minWidth: 24,
                                          height: 24,
                                          borderRadius: "50%",
                                          backgroundColor: "#ccc",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginRight: 8,
                                        }}
                                      >
                                        {selectedUser?.email[0]}
                                      </div>
                                    )}
                                    <span>
                                      {(() => {
                                        const text = truncateText(
                                          selectedUser?.username ||
                                            selectedUser?.first_name ||
                                            selectedUser?.last_name ||
                                            selectedUser?.email?.split(
                                              "@"
                                            )[0] ||
                                            "",
                                          18
                                        );

                                        const words = text.split(" ");
                                        return words.length > 10
                                          ? `${words.slice(0, 8).join(" ")}...`
                                          : text;
                                      })()}
                                    </span>
                                  </div>
                                </Tooltip>
                              ),
                            }
                          : {
                              label: values?.Assignee ? (
                                <Tooltip
                                  title={values.Assignee}
                                  placement="top"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        minWidth: 24,
                                        height: 24,
                                        borderRadius: "50%",
                                        backgroundColor: "#ccc",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginRight: 8,
                                      }}
                                    >
                                      {values?.Assignee[0]}
                                    </div>
                                    <span>
                                      {values?.Assignee?.split("@")[0]}
                                    </span>
                                  </div>
                                </Tooltip>
                              ) : (
                                "--"
                              ),
                            }
                      }
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onSearch={handleSearch}
                      // filterOption={(input, option) =>
                      //     option.label.toLowerCase().includes(input.toLowerCase())
                      //   }
                      labelInValue
                      onSelect={(value: any) => {
                        setAssigneeId(value?.value);
                        setValues({ ...values, Assignee: value });
                      }}
                      notFoundContent={
                        showInvite && (
                          <Button
                            type="primary"
                            onClick={handleInviteClick}
                            style={{ width: "100%" }}
                          >
                            Invite {searchTerm}
                          </Button>
                        )
                      }
                      optionLabelProp="label"
                    >
                      {AssignOptions.map((option: any, index: any) => (
                        <Select.Option
                          key={index}
                          value={option.value}
                          label={option.label}
                        >
                          <Tooltip title={option.label} placement="top">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {option.image ? (
                                <img
                                  src={option.image}
                                  alt={option.label}
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: "50%",
                                    marginRight: 8,
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    minWidth: 24,
                                    height: 24,
                                    borderRadius: "50%",
                                    backgroundColor: "#ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: 8,
                                  }}
                                >
                                  {option.label ? option.label[0] : "-"}
                                </div>
                              )}
                              <span> {truncateText(option.label, 20)}</span>
                            </div>
                          </Tooltip>
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
            {/* <Col md={6} className={classes.RightSide}>
              <div className={classes.headerRight}>
                <h6 className={classes.activeHeading}>Comments</h6>
                <h6
                  className={classes.unActiveHeading}
                  style={{ marginLeft: "20px" }}
                >
                  Activity
                </h6>
              </div>
              <div
                style={{ borderBottom: "1px solid #E5E5E5", padding: "10px" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <label className={classes.name}>you</label>
                  <p className={classes.DateTime}>05.12.24, 10:10 pm</p>
                </div>

                <p className={classes.para}>
                  Revolve around ensuring digital connectivity and inclusivity.
                  With the increasing reliance on technology for communication,
                  education, work, and various servi ces, bridging the digital
                  divide has become essential.
                </p>
              </div>
            </Col> */}
          </Row>
        </div>
      </Modal>
      {isModalVisible && (
        <InviteUserModal
          isActive={isModalVisible}
          emailToInvite={emailToInvite ?? ""}
          onClose={closeModal}
        />
      )}
    </>
  );
};
